import { render, staticRenderFns } from "./jointSku.vue?vue&type=template&id=10d49b01&scoped=true&lang=pug&"
import script from "./jointSku.vue?vue&type=script&lang=js&"
export * from "./jointSku.vue?vue&type=script&lang=js&"
import style0 from "./jointSku.vue?vue&type=style&index=0&id=10d49b01&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d49b01",
  null
  
)

export default component.exports