<template lang="pug">
div
  .flex_box
    el-select.w150.mb5(:size='size' v-for='(item,index) in result' :key='index' v-model=`result[index]` @change='change' filterable)
      el-option(v-for='(item,index) in data' :key='item + index' :label='item' :value='item')
    //- el-input.w100.mb5(placeholder='拼接符' :size='size' v-model.trim='joint' v-if='result.length > 1' @change='change')
    el-button.ml5.mb5(:size='size' @click='add' @change='change') 添加列
    el-button.mb5(:size='size' @click='reset' @change='change') 删除列
  .tips.mt10(v-show='result.length > 1') {{ showSKU }}
</template>

<script>
export default {
  name: "JointSku",

  props: {
    data: [Array],
  },

  watch: {
    result() {
      if (this.result.length === 1) {
        this.joint = ""
      }
      // this.$emit("input", { emailRiGoodsSkuLine: this.liveSKU, splitCode: this.joint })
      this.$emit("input", { emailRiGoodsSkuLine: this.liveSKU })
    },

    joint() {
      // this.$emit("input", { emailRiGoodsSkuLine: this.liveSKU, splitCode: this.joint })
      this.$emit("input", { emailRiGoodsSkuLine: this.liveSKU })
    },
  },

  data() {
    return {
      result: [""],
      joint: "/",
    }
  },

  computed: {
    size() {
      return this.$store.state.size
    },

    // liveSKU() {
    //   return this.result.join(this.joint)
    // },

    liveSKU() {
      return this.result.join("/")
      // const msg = `您将使用 【${this.result.join('-')}】 这${this.result.length}列拼接成一个 SKU`
      // return msg
    },

    showSKU() {
      const msg = `您将使用 【${this.result.join('-')}】 这${this.result.length}列拼接成一个 SKU`
      return msg
    },
  },

  mounted() {},

  methods: {
    reset() {
      if (this.result.length < 2) return
      this.result.pop()
    },

    add() {
      if (!this.result[this.result.length - 1]) {
        this.$message.error("选择后再继续创建")
        return
      }
      this.result.push(null)
    },

    // isOk() {
    //   return new Promise((resolve, reject) => {
    //     if (this.result.length === 1 && this.result[0]) {
    //       resolve(true)
    //     } else {
    //       if (this.joint && this.result.length > 1) {
    //         resolve(true)
    //       } else {
    //         reject(false)
    //       }
    //     }
    //   })
    // },

    isOk() {
      return new Promise((resolve, reject) => {
        if (this.result.length === 1 && this.result[0]) {
          resolve(true)
        } else {
          if (this.result[this.result.length - 1]) {
            resolve(true)
          } else {
            reject(false)
          }
        }
      })
    },

    change() {
      // this.$emit("change", this.result, this.joint)
      this.$emit("change", this.result)
    },
  },
}
</script>

<style lang="scss" scoped>
.flex_box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.tips {
  color: green;
  font-size: 14px;
}
</style>
