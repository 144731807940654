<!-- emailRiRetailerId -->
<template lang="pug">
div.contactLens
  div.auto(v-show='pageStatus === 1' :style='{"--tableHeight": tableHeight}')
    search-bar(:row='3' ref='searchBar')
      template(v-slot:content)
        el-form(:size='size' :inline="true" :model="searchForm" label-width="80px" class="search")
          el-form-item(label="零售商")

            el-select( v-model='searchForm.sysCiId' clearable)
              el-option(
                v-for='item in selectList'
                :key='item.sysCiId'
                :value='item.sysCiId'
                :label='item.sysCiFullName'
              )
      template(v-slot:footer)
        el-button(:size='size' type='primary' icon='el-icon-search' @click='search') 查询
        el-button(:size='size' icon='el-icon-delete' @click='reset') 清空
        el-button(:size='size' type='primary' icon='el-icon-aim' @click='analysisEmail' :loading='analysisEmailLoading') 解析
    ps-table.mt10.auto_height(
      height='100%'
      v-loading='tableLoading'
      ref='psTable'
      :header-cell-style="headerCellStyle"
      :size="size"
      :data='tableData'
      :columns='columns'
      :columns-props="columnsProps"
    )
      template(#启用状态="{ row = {} }")
        el-switch(v-model='row.i' :active-value='0' :inactive-value='1' active-text='启用' inactive-text='停用')
      template(#操作="{ row ={} }")
        el-button(type='text' :size='size' icon='el-icon-document' @click='seeRules(row)') 解析规则
    pagination(ref='pagination' :total='total' :current-page='currentPage' @size-change='changeSize' @current-change='changePage')
  seeRules(v-if='pageStatus === 3' @back='clickBack' :row='currentEditRow' ref='see')
</template>

<script>
import pagination from "@/components/assembly-pagination/index"
import seeRules from "./components/seeRules"
import { configMixin } from "./configMixin" // 配置类混入

export default {
  name: "contactLens",

  components: {
    // editRules,
    pagination,
    seeRules,
  },

  mixins: [configMixin],
  data() {
    return {
      analysisEmailLoading: false, // 解析邮件loading按钮
      selectList: [], // 零售商列表
      tableLoading: false, // 表格 loading
      currentEditRow: null, // 当前编辑行
      searchForm: {}, // 搜索栏数据
      currentPage: 1, // 分页当前第几页
      total: 0, // 分页 total
      page: 1, // 分页默认第几页
      pageSize: 10, // 分页默认尺寸
      searchData: {}, // 头部搜索数据
      pageStatus: 1, // 当前页面状态  1：主页  2：新增解析规则 3：查看解析规则
    }
  },

  methods: {
    clickBack() {
      if (this.$refs.see.model == 1) {
        this.$refs.see.model = 0
      } else if (this.$refs.see.model == 2) {
        this.$refs.see.model = 0
      } else {
        this.pageStatus = 1
      }
    },

    getTableData(data) {
      this.tableLoading = true
      const { page, pageSize } = this
      this.$server
        .getShopkeeperList({ ...data, page, pageSize })
        .then(res => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.tableLoading = false
        })
        .catch(() => {
          this.tableLoading = false
        })
    },

    seeRules(row) {
      this.currentEditRow = row
      this.pageStatus = 3
    },

    analysisEmail(row) {
      this.analysisEmailLoading = true
      const { sysCiFullName, sysCiId } = row
      this.$server.analysisEmail({ sysCiFullName, sysCiId }).then(res => {
        if (res.ok) {
          this.$message.success(res.msg)
          this.analysisEmailLoading = false
        } else {
          this.$message.error(res.msg)
          this.analysisEmailLoading = false
        }
      })
    },

    addNewRules(row) {
      this.currentEditRow = row
      this.pageStatus = 2
    },

    changeSize(val) {
      this.pageSize = val
      this.getTableData({ ...this.searchForm, sysCiType: 3 })
    },

    changePage(val) {
      this.currentPage = val
      this.page = val
      this.getTableData({ ...this.searchForm, sysCiType: 3 })
    },

    reset() {
      this.currentPage = 1
      this.page = 1
      this.restPaginationParams()
      this.searchForm = {}
      this.getTableData({ sysCiType: 3 })
    },

    search() {
      this.currentPage = 1
      this.page = 1
      this.restPaginationParams()
      this.getTableData({ ...this.searchForm, sysCiType: 3 })
    },

    restPaginationParams() {
      this.page = 1
      this.pageSize = 10
    },

    getGHSList() {
      this.$server.getGHSList({ sysCiType: 3 }).then(res => {
        this.selectList = res.data
      })
    },
  },

  computed: {
    tableHeight() {
      let init = 130
      const { fastNav, isHeader } = this.$store.state
      fastNav === 1? init -= 31: 0
      isHeader === 1? init -= 60 : 0
      console.log(init);
      return init + 'px'
    },
  },

  mounted() {
    this.pageSize = this.$store.state.initPageSize ? this.$store.state.initPageSize : 10;
    console.log(this.$store.state.initPageSize)
    this.getTableData({ sysCiType: 3 })
    this.getGHSList();
  },
}
</script>

<style lang="scss">
.search {
//搜索展开收起按钮位置设置
  .searchOpenBtn {
    float: right;
  }

//表单每行底部外边距设置
  .el-form-item {
    margin-bottom: 10px;
  }

.el-select {
  width: 200px;
}

.el-input {
  width: 200px;
}

//分割线外边距设置
  .el-divider--horizontal {
    margin: 0 0 10px 0;
  }
}

.el-dialog {
.el-select {
  width: 100%;
}
}
</style>

<style lang="scss" scoped>
:root {
  --c: "red";
}

.auto {
  /* height: calc(100vh - 130px); */
  height: calc(100vh - var(--tableHeight));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.auto_height {
  flex: 1;
  overflow: auto;
}
</style>

<style scoped>
::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
</style>
