export const configMixin = {
  computed: {
    // 此 size 从 vuex 中读取，用于全局主题样式大小
    size() {
      return this.$store.state.size
    },
  },

  methods: {
  },

  data() {
    return {
      // 组件表格行 props 参数
      columnsProps: {
        align: 'center'
      },

      // 表头颜色
      headerCellStyle: {
        background: '#f5f7fa',
        color: '#515a6e',
        fontWeight: '900'
      },

      //简单 table 数据
      tableData: [],

      // 表格的配置项
      columns: [
        { prop: "sysCiType", label: "客户类型", formatter: row => row.sysCiType === 3 ? '零售商' : '其他' },
        { prop: "sysCiFullName", label: "全称" },
        { prop: "sysCiShortName", label: "简称" },
        { prop: "sysCiShortCode", label: "简码" },
        { prop: "sysCiAddress", label: "地址" },
        { prop: "sysCiContacts", label: "联系人" },
        { prop: "sysCiTel", label: "电话" },
        // { prop: "i", label: "启用状态", custom: true },
        { prop: "h", label: "操作", custom: true, width: 200 },
      ],
    }
  },
}