<template lang="pug">
div
  assembly-card-page(:title='"邮件解析规则"' @back='back')
    el-button(type='primary' @click='selectModel' :size="size" v-show='model == 0') 新增
    edit-rules.mt10(v-if='model == 1' @back='model=1' :row='row' ref='editRules' @submit='submitRules')
    edit-model2(v-if='model == 2' @back='model=1' :row='row' ref='editRules2' @submit='submitRulesModel2')
    .auto(v-if='model == 0' :style="{ '--tableHeight': tableHeight }")
      ps-table.mt10.auto_height(
        height='100%'
        v-loading='tableLoading'
        ref='psTable'
        :header-cell-style="headerCellStyle"
        :size="size"
        :data='tableData'
        :columns='columns'
        :columns-props="columnsProps"
      )
        template(#启停状态='{ row = {} }')
          el-switch(v-model='row.emailRiStatus' :active-value='0' :inactive-value='1' active-text='启用' inactive-text='停用' @change='changeSwitch(row)')
        template(#操作='{ row = {} }')
          el-button(:size='size' type='text' icon='el-icon-document-checked' @click='seeDetail(row)') 详细规则
          el-button(:size='size' type='text' icon='el-icon-delete' @click='delRules(row)') 删除
      pagination(ref='pagination' :total='total' :current-page='currentPage' @size-change='changeSize' @current-change='changePage')

      assembly-dialog(title='查看详情' :visible='detailDialogShow' width='80%' @close='closeDetailDialog' @before-close="closeDetailDialog")
        .detail_main
          .detail_box(v-for='(value, key) in detailList' :key='key' :style="getName(key) === '' ? empty : {}")
            .detail_title {{ getName(key) }}
            .detail_item {{ value || '---' }}

        el-button.mb5(slot='footer' type='primary' :size='size' @click='closeDetailDialog') 确定

      //- 模式选择，是lot模式还是其他
      assembly-dialog(
        title='请选择新增模式'
        :close-on-click-modal='false'
        :visible.sync='modelDialogVisibly'
        width='30%'
        @before-close="modelDialogVisibly = false"
      )
        .content_model
          el-button(size='medium' @click='changeModel(1)') LOT 模式
          el-button(size='medium' @click='changeModel(2)') UDI 模式
        template(v-slot:footer)
          el-button(type='primary' @click='modelDialogVisibly = false') 取消

</template>

<script>
import editModel2 from "./editModel2"
import editRules from "./editRules"
import pagination from "@/components/assembly-pagination/index"
import { nameList } from "./editRulesConfig" // 字段映射
import { configMixin } from "../configMixin" // 配置类混入
import assemblyCardPage from "@/components/assembly-card-page"
import AssemblyDialog from "components/assembly-dialog";
export default {
   name: "EditRules",

   components: { AssemblyDialog, assemblyCardPage, pagination, editRules, editModel2 },

   props: {
      row: Object,
   },

   mixins: [configMixin, nameList],

   data() {
      return {
         modelType: 1,
         modelDialogVisibly: false, // 选择dialog是否显示
         model: 0, // 当前是哪种模型，0： 主页  1 是普通模型， 2是多页模型
         isAdd: false, // 是否新增
         empty: { display: "none" },
         detailDialogShow: false, // 查看详情弹窗
         detailList: {}, // 查看详细列表
         tableLoading: false, // 表格 loading
         tableData: [],
         columns: [
            { prop: "emailRiGoodsSkuLine", label: "商品SKU列" },
            { prop: "emailRiProductNameLine", label: "厂家品种名称列" },
            { prop: "emailRiProductTypeLine", label: "商品类型列" },
            { prop: "emailRiDeliveryNumLine", label: "发货数量列" },
            // { prop: "emailRiColorNumLine", label: "色号列" },
            // { prop: "emailRiCylLine", label: "柱镜列" },
            // { prop: "emailRiSphLine", label: "球镜列" },
            { prop: "emailRiSizeLine", label: "尺寸列" },
            // { prop: "emailRiModelLine", label: "型号列" },
            { prop: "", label: "启停状态", custom: true, width: 150 },
            { prop: "", label: "操作", custom: true, width: 150 },
         ],
         currentPage: 1, // 分页当前第几页
         total: 0, // 分页 total
         pageNum: 1, // 分页默认第几页
         pageSize: 10, // 分页默认尺寸
      }
   },

   computed: {
      size() {
         return this.$store.state.size
      },

      tableHeight() {
         let init = 245
         const { fastNav, isHeader } = this.$store.state
         fastNav === 1 ? (init -= 31) : 0
         isHeader === 1 ? (init -= 60) : 0
         return init + "px"
      },
   },

   mounted() {
      this.pageSize = this.$store.state.initPageSize ? this.$store.state.initPageSize : 10;
      this.getRulesList()

   },

   methods: {
      changeModel(type) {
         this.model = type
         this.modelDialogVisibly = false
      },

      selectModel() {
         this.modelDialogVisibly = true
      },

      submitRules(data) {
         this.$server
            .saveRole({ ...data, emailRiStatus: 0 })
            .then(res => {
               if (res.ok) {
                  this.$message.success("邮件解析规则保存成功")
                  this.model = 0
                  // this.pageStatus = 1
                  this.isAdd = false
                  this.$refs.editRules.submitLoading = false
                  this.getRulesList()
               } else {
                  this.model = 0
                  this.$message.error(res.msg)
                  this.$refs.editRules.submitLoading = false
                  this.getRulesList()
               }
            })
            .catch(_ => {
               this.model = 0
               this.$message.error("保存失败，请稍后再试")
               this.$refs.editRules2.submitLoading = false
            })
      },

      submitRulesModel2(data) {
         this.$server
            .saveRole({ ...data, emailRiStatus: 0 })
            .then(res => {
               if (res.ok) {
                  this.$message.success("邮件解析规则保存成功")
                  this.model = 0
                  // this.pageStatus = 1
                  this.isAdd = false
                  this.$refs.editRules2.submitLoading = false
                  this.getRulesList()
               } else {
                  this.model = 0
                  this.$message.error(res.msg)
                  this.$refs.editRules2.submitLoading = false
                  this.getRulesList()
               }
            })
            .catch(_ => {
               this.model = 0
               this.$message.error("保存失败，请稍后再试")
               this.$refs.editRules2.submitLoading = false
            })
      },

      changePage(val) {
         this.pageNum = val
         this.getRulesList({ page: val, pageSize: this.pageSize })
      },

      changeSize(val) {
         this.currentPage = 1
         this.pageSize = val
         this.getRulesList({ page: 1, pageSize: val })
      },

      restPaginationParams() {
         this.pageNum = 1
         this.pageSize = 10
      },

      closeDetailDialog() {
         this.detailDialogShow = false
      },

      seeDetail(row) {
         console.log('row :>> ', row);
         this.detailList = row
         this.detailDialogShow = true
      },

      getName(k) {
         return this.nameList.filter(item => item.key == k)[0]?.value || ""
      },

      changeSwitch(row) {
         this.$server.saveRole({ ...row }).then(res => {
            if (res.ok) {
               this.$message.success(res.msg)
               this.getRulesList()
            } else {
               this.$message.error(res.msg)
               this.getRulesList()
            }
         })
      },

      back() {
         this.$emit("back")
      },

      submit() {
         this.$emit("submit")
      },

      getRulesList(data) {
         // 初始化列表
         this.tableLoading = true
         this.$server
            .getAllRulesPage({ emailRiRetailerId: this.row.sysCiId, page: this.currentPage, pageSize: this.pageSize, ...data })
            .then(res => {
               this.total = res.data.total
               this.tableData = res.data.records
               this.tableLoading = false
            })
            .catch(() => {
               this.tableLoading = false
            })
      },

      delRules(row) {
         this.$confirm("此操作将永久删除此条记录, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
         }).then(() => {
            const id = row.emailRiId
            this.$server.delRules({ id }).then(res => {
               if (res.ok) {
                  this.getRulesList()
                  this.$message.success(res.msg)
               } else {
                  this.$$message.error(res.msg)
               }
            })
         })
      },
   },
}
</script>

<style lang="scss" scoped>
.hidden {
   display: none;
}

.line_item {
   height: 30px;
   background: red;
   line-height: 28px;
}
</style>

<style scoped>
.auto {
   /* height: calc(100vh - 245px); */
   height: calc(100vh - var(--tableHeight));
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.auto_height {
   flex: 1;
   overflow: auto;
}

.content_model {
   display: flex;
   justify-content: space-around;
}

.flex_b {
   display: flex;
}

.detail_title {
   padding: 10px;
   display: flex;
   width: 100px;
   background: #fafafa;
}

.detail_item {
   padding: 10px;
   flex: 1;
}

.detail_main {
   display: flex;
   flex-wrap: wrap;
}

.detail_box {
   display: flex;
   border: 1px solid #ebeef5;
   width: 33%;
}
</style>
