export const config = {
  data() {
    return {
      pageShow: [
        // 邮件解析下拉选择框
        // { key: 'emailRiGoodsSkuLine', value: '* 商品sku列' },
        {
          key: 'emailRiOrderNumLine',
          value: '厂家订货号列'
        },
        {
          key: 'emailRiProductNameLine',
          value: '厂家品种名称列'
        },
        {
          key: 'emailRiGoodsNameLine',
          value: '厂家商品名称列'
        },
        // {
        //   key: 'emailRiSphLine',
        //   value: '球镜列'
        // },
        // {
        //   key: 'emailRiCylLine',
        //   value: '柱镜列'
        // },
        // {
        //   key: 'emailRiModelLine',
        //   value: '型号'
        // },
        // {
        //   key: 'emailRiColorNumLine',
        //   value: '色号'
        // },
        // { key: 'emailRiSizeLine', value: '尺寸' },
        {
          key: 'emailRiDepartmentLine',
          value: '收货部门列'
        },
        {
          key: 'emailRiCreateDateLine',
          value: '生产日期列'
        },
        {
          key: 'emailRiLoseDateLine',
          value: '失效日期列'
        },
        {
          key: 'emailRiBatchNumLine',
          value: '生产批号列'
        },
        {
          key: 'emailRiLotLine',
          value: 'lot列'
        },
        {
          key: 'emailRiProductSkuLine',
          value: '厂家品种sku列'
        },
        // { key: 'emailRiCodeTypeLine', value: '编码类型' },
        // { key: 'emailRiProductTypeLine', value: '商品类型' },
        {
          key: 'emailRiIsCustomLine',
          value: '是否定制列'
        },
        // { key: 'emailRiUserSkuLine', value: '自定义sku' },
        {
          key: 'emailRiDeliveryNumLine',
          value: '发货数量列'
        },
        // { key: 'emailRiCostLine', value: '成本列' },
        // { key: 'yangfan', value: '单价列' },
        {
          key: 'emailRiCostCalculationType',
          value: '成本价格类型列'
        },
        {
          key: 'emailRiCostType',
          value: '成本价格是否含税'
        },
        // {
        //   key: 'emailRiDegreeLine',
        //   value: '度数'
        // }
      ],
      rules: {
        emailRiOrderNumLine: [{
          required: true,
          message: '此项是必选项',
          trigger: 'change'
        }],
        emailRiDepartmentLine: [{
          required: true,
          message: '此项是必选项',
          trigger: 'change'
        }],
        emailRiCreateDateLine: [{
          required: true,
          message: '此项是必选项',
          trigger: 'change'
        }],
        emailRiLoseDateLine: [{
          required: true,
          message: '此项是必选项',
          trigger: 'change'
        }],
        emailRiBatchNumLine: [{
          required: true,
          message: '此项是必选项',
          trigger: 'change'
        }],
        emailRiLotLine: [{
          required: true,
          message: '此项是必选项',
          trigger: 'change'
        }],
        emailRiDeliveryNumLine: [{
          required: true,
          message: '此项是必选项',
          trigger: 'change'
        }],
        emailRiCostLine: [{
          required: true,
          message: '此项是必选项',
          trigger: 'blur'
        }],
        emailRiUdiLine: [{
          required: true,
          message: '此项是必选项',
          trigger: 'change'
        }],
        emailRiUnitpriceLine: [{
          required: true,
          message: '此项是必选项',
          trigger: 'blur'
        }],
      },
      customRules: {
        emailRiUnitpriceLine: [{
          required: false,
          message: '此项是必选项',
          trigger: 'change'
        }],
        emailRiCostLine: [{
          required: false,
          message: '此项是必选项',
          trigger: 'change'
        }],
      },
      isAllowEmpty: {
        required: false
      }
    }
  }
}

export const nameList = {
  data() {
    return {
      nameList: [
        // 名字映射表
        {
          key: 'emailRiUdiLine',
          value: 'UDI列'
        },
        {
          key: 'emailRiOrderNumLine',
          value: '厂家订货号列'
        },
        {
          key: 'emailRiProductNameLine',
          value: '厂家品种名称列'
        },
        {
          key: 'emailRiGoodsNameLine',
          value: '厂家商品名称列'
        },
        // {
        //   key: 'emailRiSphLine',
        //   value: '球镜列'
        // },
        // {
        //   key: 'emailRiCylLine',
        //   value: '柱镜列'
        // },
        // {
        //   key: 'emailRiModelLine',
        //   value: '型号列'
        // },
        // {
        //   key: 'emailRiColorNumLine',
        //   value: '色号列'
        // },
        // { key: 'emailRiSizeLine', value: '尺寸列' },
        {
          key: 'emailRiDepartmentLine',
          value: '收货部门列'
        },
        {
          key: 'emailRiCreateDateLine',
          value: '生产日期列'
        },
        {
          key: 'emailRiLoseDateLine',
          value: '失效日期列'
        },
        {
          key: 'emailRiBatchNumLine',
          value: '生产批号列'
        },
        {
          key: 'emailRiLotLine',
          value: 'lot列'
        },
        {
          key: 'emailRiGoodsSkuLine',
          value: '商品sku列'
        },
        {
          key: 'emailRiProductSkuLine',
          value: '厂家品种sku列'
        },
        // { key: 'emailRiCodeTypeLine', value: '编码类型列' },
        // { key: 'emailRiProductTypeLine', value: '商品类型列' },
        {
          key: 'emailRiIsCustomLine',
          value: '是否定制列'
        },
        // { key: 'emailRiUserSkuLine', value: '自定义sku列' },
        {
          key: 'emailRiDeliveryNumLine',
          value: '发货数量列'
        },
        {
          key: 'emailRiCostLine',
          value: '成本列'
        },
        {
          key: 'emailRiUnitpriceLine',
          value: '单价列'
        },
        {
          key: 'emailRiCostCalculationType',
          value: '成本价格类型列'
        },
        {
          key: 'emailRiCostType',
          value: '成本价格是否含税列'
        },
        // { key: 'emailRiEmailType', value: '零售商邮箱类型' },
        // { key: 'emailRiId', value: '主键' },
        // { key: 'emailRiName', value: '邮件规则名称' },
        {
          key: 'emailRiRetailerEmail',
          value: '零售商邮箱'
        },
        // { key: 'emailRiRetailerEmailAuthorization', value: '零售商邮箱授权码' },
        // { key: 'emailRiRetailerEmailNum', value: '零售商邮箱账号' },
        {
          key: 'emailRiRetailerId',
          value: '零售商ID'
        },
        {
          key: 'emailRiRetailerName',
          value: '零售商名称'
        },
        {
          key: 'emailRiSku',
          value: '供应商SKU'
        },
        {
          key: 'emailRiSupplierEmail',
          value: '供应商邮箱'
        },
        {
          key: 'emailRiSupplierId',
          value: '供应商ID'
        },
        {
          key: 'emailRiSupplierName',
          value: '供应商名称'
        },
        // {
        //   key: 'emailRiDegreeLine',
        //   value: '度数'
        // }
      ]
    }
  }
}