<template lang="pug">
div
  el-button.mb10(size='mini' @click='add' type='primary') 新增表对应关系
  .flex_box
    .map(v-for='(item, index) in mapList' :key='index')
      .mr5 表1
      el-select.w200(v-model=`mapList[index]['sheet1']` placeholder='表1列' filterable)
        el-option(v-for='item in data' :key='item' :label='item' :value='item')
      .ml5.mr5 【对应】
      .mr5 表2
      el-select.w200(v-model=`mapList[index]['sheet2']` placeholder='表2列' filterable)
        el-option(v-for='item in data' :key='item' :label='item' :value='item')
      el-button.ml5(@click='del(index)') 删除此条
</template>

<script>
export default {
  name: "CustomMap",

  props: {
    data: Array,
  },

  watch:{
    mapList() {
      this.$emit('input', [...this.filterValue(this.mapList)])
    }
  },

  data() {
    return {
      mapList: [{}],
    }
  },

  mounted() {
  },

  methods: {
    filterValue(val) {
     return val.filter(item => JSON.stringify(item) != "{}")
    },

    add() {
      const target = this.mapList
      const flag = target[target.length - 1]["sheet1"] && target[target.length - 1]["sheet2"]
      if (!flag) {
        this.$message.error("请选择后再继续创建")
      } else {
        this.mapList.push({})
      }
    },

    del(i) {
      if(this.mapList.length === 1) {
        this.$message.error('至少保留一个对应列')
        return
      }
      this.mapList.splice(i,1)
    }
  },
}
</script>

<style lang="scss" scoped>
.map {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  border: 1px solid #ebeef5;
  width: 650px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.flex_box {
  display: flex;
  flex-wrap: wrap;
}
</style>
