<template>
  <div>
    <div class="box">
      <el-card class="box-card" shadow="never" :body-style="{ padding: '10px' }">
        <div class="flex_box" ref="searchBar">
          <div class="row" ref="row_content">
            <slot name="content"></slot>
          </div>
        </div>
        <div class="light">
          <div class="line mb10"></div>
          <slot name="footer"></slot>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "psSearchBar",

  props: {
    // row 代表一行摆列几个栏
    row: {
      type: Number,
      default: 4,
    },
  },

  data() {
    return {
      close: true,
      height: 80,
      style: {
        height: "auto",
      },
    }
  },

  mounted() {
    this.$nextTick(() => {
      const h = this.$refs.row_content.offsetHeight
      this.height = h
    })
    // this.$refs.searchBar.style.setProperty("--w", 100 / this.row + "%")
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
.row {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  //表单每行底部外边距设置
  .el-form-item {
    margin-bottom: 10px;
  }

  .el-select {
    width: 200px;
  }

  .el-input {
    width: 200px;
  }
}

//.line {
//  border-top: 1px solid #ebeef5;
//}

.flex_box {
  display: flex;
}

.btn_box {
  width: 60px;
}

.light {
  z-index: 3;
}
</style>

<style>

.row .bar_item {
  box-sizing: border-box;
  padding: 5px;
  width: var(--w);
  display: flex;
  align-items: center;
}

.row .bar_item .label {
  text-align: right;
  padding-right: 10px;
}
</style>
