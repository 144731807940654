<template lang="pug">
div
   row-bar(:row="4")
      template(v-slot:content)
         .search
            el-form(
               :model="formData",
               inline,
               label-width="100px",
               ref="formData",
               :rules="formDataRules"
            )
               el-form-item(label="供货商")
                  el-select(
                     :size="size",
                     v-model="formData.emailRiSupplierId",
                     placeholder="选择供货商",
                     @change="selectSupport"
                  )
                     el-option(
                        v-for="item in GYSList",
                        :key="item.sysCiId",
                        :label="item.sysCiFullName",
                        :value="item.sysCiId"
                     )
               el-form-item(label="供货商邮箱", prop="emailRiSupplierEmail")
                  el-input(
                     :size="size",
                     v-model="formData.emailRiSupplierEmail"
                  )
               el-form-item(label="零售商")
                  el-input(
                     :value="row.sysCiFullName",
                     :size="size",
                     :disabled="true"
                  )
               el-form-item(label="零售商邮箱", prop="emailRiRetailerEmail")
                  el-input(
                     :size="size",
                     v-model="formData.emailRiRetailerEmail"
                  )
            el-divider
            el-button(
               :size="size",
               type="primary",
               @click="uploadDialogShow = true"
            ) 导入发货单邮件
            el-tag.ml30.mt10(style="color: #000", v-show="file.name") {{ `当前导入文件 : ${file.name}` }}
   //- 邮件解析下拉框
   el-card.mb10(shadow="never", style="margin-top: 20px")
      .bar_item
         .label.w100.fz14.mb10.red * SKU 列
         jointSku(
            :data="result",
            @change="changeSKU",
            ref="skuSelect",
            v-model="customSku"
         )
   el-form.flex_box(
      ref="form",
      :model="formData",
      :rules="rules",
      label-width="130px"
   )
      el-form-item(
         v-for="(i, index) in pageShow",
         :key="index",
         :label="i.value",
         :prop="i.key"
      )
         el-select(
            :size="size",
            v-model="formData[i.key]",
            clearable,
            filterable
         )
            el-option(
               v-for="item in result",
               :key="item",
               :label="item",
               :value="item"
            )
      //- 成本与单价二选一
      el-form-item(
         prop="emailRiCostLine",
         label="成本列",
         :rules="formData.emailRiUnitpriceLine ? isAllowEmpty : null"
      )
         el-select(
            :size="size",
            v-model="formData.emailRiCostLine",
            clearable,
            filterable
         )
            el-option(
               v-for="item in result",
               :key="item",
               :label="item",
               :value="item"
            )
      el-form-item(
         prop="emailRiUnitpriceLine",
         label="单价列",
         :rules="formData.emailRiCostLine ? isAllowEmpty : null"
      )
         el-select(
            :size="size",
            v-model="formData.emailRiUnitpriceLine",
            clearable,
            filterable
         )
            el-option(
               v-for="item in result",
               :key="item",
               :label="item",
               :value="item"
            )

   .flex_btn
      el-button(
         type="primary",
         :size="size",
         @click="submit",
         :loading="submitLoading"
      ) 提交规则
   //- 导入邮件模块 dialog
   assembly-dialog(
      title="导入邮件",
      :visible="uploadDialogShow",
      width="30%",
      @close="closeDialog",
      @before-close="closeDialog"
   )
      el-upload(
         ref="upload",
         action="/api/email/role-info/getExcelHeadList",
         accept=".xlsx,.xls",
         name="multipartFile",
         :on-change="(f) => { file = f; }",
         :auto-upload="false",
         :limit="1",
         :data="{ line, type }",
         :headers="{ Authorization }",
         :on-success="isUploaded",
         :on-error="isUploadError"
      )
         el-button(
            :size="size",
            type="primary",
            style="width: 100px",
            @click="() => { $refs.upload.clearFiles(); }"
         ) 选择文件
         .el-upload__tip(
            slot="tip",
            style="display: inline-block; margin-left: 20px; color: red"
         ) * 只能上传 xlsx 格式的 excel 文件
      .line.mt10.mb10
         el-input-number(
            :size="size",
            placeholder="行号",
            style="width: 100px",
            v-model="line",
            :controls="false"
         )
         span.ml20.fz13(style="color: red") * 请输入需要解析的行号 * 大于 1 的正整数
      el-button.mb5(
         slot="footer",
         type="success",
         :size="size",
         @click="send",
         style="width: 100px",
         :loading="upLoadLoading"
      ) 解析表头
</template>

<script>
import jointSku from "./jointSku.vue"
import { config } from "./editRulesConfig.js"
import assemblyCardPage from "@/components/assembly-card-page"
import rowBar from "./rowBar"
import AssemblyDialog from "components/assembly-dialog";
export default {
   name: "EditRules",

   components: { AssemblyDialog, assemblyCardPage, rowBar, jointSku },

   mixins: [config],

   props: {
      row: Object,
   },

   data() {
      return {
         yyy: true,
         type: 1,
         customSku: {}, // 拼接 sku
         upLoadLoading: false, // 导入文件时的loading
         submitLoading: false, // 提交时的 loading 按钮，防止暴击
         formData: { emailRiType: 1 },
         file: [], // 保存的 excel 文件
         uploadDialogShow: false, // 导入 dialog 是否显示
         line: 1, // 当前的行号
         result: [], // 导入文件后返回的结果
         GYSList: [], // 供应商列表
         formDataRules: {
            emailRiSupplierEmail: [{ pattern: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/, message: '邮箱格式不正确', trigger: 'blur' }],
            emailRiRetailerEmail: [{ pattern: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/, message: '邮箱格式不正确', trigger: 'blur' }]
         }
      }
   },

   computed: {
      size() {
         return this.$store.state.size
      },

      Authorization() {
         return sessionStorage.getItem("Authorization") || ""
      },
   },

   mounted() {
      this.formData.emailRiRetailerName = this.row.sysCiFullName
      this.formData.emailRiRetailerId = this.row.sysCiId
      this.$server.getGHSList().then(res => {
         this.GYSList = res.data
      })
   },

   methods: {
      changeSKU() {
         // if()
      },

      selectSupport(id) {
         this.formData.emailRiSupplierName = this.GYSList.filter(item => item.sysCiId === id)[0]?.sysCiFullName || ""
      },

      back() {
         this.$emit("back")
      },

      submit() {
         this.$refs.skuSelect
            .isOk()
            .then(res => {
               if (!res) {
                  this.$message.error("请选择商品 SKU 列")
                  return
               } else {
                  this.$refs.formData.validate(valid => {
                     if (valid) {
                        this.$refs.form.validate(res => {
                           if (res) {
                              const data = { ...this.formData, ...this.customSku }
                              this.$emit("submit", data)
                              this.submitLoading = true
                           } else {
                              this.$message.error("请选择必选项后再提交")
                              return
                           }
                        })
                     }
                  })

                  // const data ={ ...this.formData, ...this.customSku }
                  // this.$emit("submit", data)
                  // this.submitLoading = true
               }
            })
            .catch(() => {
               this.$message.error("请选择商品 SKU 列")
               return
            })
         // const sku = this.formData.emailRiGoodsSkuLine
         // if (!sku) {
         //   this.$message.error("请选择商品 SKU 列")
         //   return
         // }
      },

      send() {
         if (!this.line) {
            this.$message.error("请输入行号")
            return
         }
         if (!this.file.name) {
            this.$message.error("请选择文件")
            return
         }
         this.line = Math.abs(this.line)
         // this.$refs.upload.submit()
         this.upLoadLoading = true
         this.$refs.upload.submit()
      },

      closeDialog() {
         this.line = 1
         this.uploadDialogShow = false
      },

      isUploadError() {
         this.$message.error("上传失败，请稍后重试")
         this.$refs.upload.clearFiles()
         this.upLoadLoading = false
      },

      isUploaded(res) {
         if (res.ok) {
            this.result = res.data
            this.uploadDialogShow = false
            this.$refs.upload.clearFiles()
            this.upLoadLoading = false
         } else {
            this.$refs.upload.clearFiles()
            this.$message.error(res.msg)
            this.uploadDialogShow = false
            this.upLoadLoading = false
         }
      },
   },
}
</script>

<style lang="scss" scoped>
.line {
   display: flex;
   align-items: center;
}

.red {
   color: red;
}

.flex_box {
   display: flex;
   flex-wrap: wrap;
}

.flex_btn {
   display: flex;
   justify-content: flex-end;
}
</style>

<style lang="scss" scoped>
.search {
   flex: 1;
   //表单每行底部外边距设置
   ::v-deep .el-form-item {
      margin-bottom: 20px !important;
   }

   ::v-deep .el-select {
      width: 200px;
   }

   ::v-deep .el-input {
      width: 200px;
   }

   //分割线外边距设置
   ::v-deep .el-divider--horizontal {
      margin: 0 0 10px 0;
   }
}

::v-deep .el-dialog {
   ::v-deep .el-select {
      width: 100%;
   }
}
</style>
